<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model layout="inline">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-radio-group v-model="selectedDate" button-style="solid">
                <a-radio-button value="1"> 本周 </a-radio-button>
                <a-radio-button value="2"> 下周 </a-radio-button>
                <a-radio-button value="3"> 今日 </a-radio-button>
                <a-radio-button value="4"> 明日 </a-radio-button>
                <a-radio-button value="5"> 后日 </a-radio-button>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item>
              <a-range-picker v-model="form.date" @change="handleDateChange" />
            </a-form-model-item>
            <a-form-model-item>
              <a-button @click="reset">重置</a-button>
            </a-form-model-item>
          </a-col>

          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <PermissionButton path="add">
                <a-button
                  @click="$router.push($route.path + '/add')"
                  type="primary"
                  >预约会议</a-button
                >
              </PermissionButton>
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <div class="scroll-panel">
        <div
          class="list"
          :style="{ width: scroll.x ? scroll.x + 'px' : '100%' }"
        >
          <div class="item" v-for="(item, index) in roomList" :key="index">
            <Room
              :room="item"
              :meetingList="list"
              @adopt="adopt"
              @reject="reject"
              @polish="polish"
              @cancel="cancel"
            />
          </div>
        </div>
      </div>
    </a-card>

    <Polish :visible="polishVisible" @cancel="cancelPolish" :text="text" />
  </div>
</template>

<script>
import screen from "@/mixins/screen";
import watermark from "@/mixins/watermark";
import {
  fetchList,
  fetchMeetingList,
  adoptMeeting,
  cancelMeeting,
} from "@/api/meeting";
import moment from "moment";
import Polish from "./polish.vue";
import Room from "./components/Room";
export default {
  name: "meeting",

  mixins: [screen, watermark],

  components: {
    Polish,
    Room,
  },

  data() {
    return {
      selectedDate: "1",
      form: {
        date: null,
      },

      roomList: [], // 会议室列表

      loading: false,
      list: [],
      total: 0,

      polishVisible: false, // 审核通过后可以润色一下
      text: {}, // 需要润色的记录
    };
  },

  activated() {
    fetchList({
      pageNum: 1,
      pageSize: 100,
    }).then((res) => {
      if (res && Array.isArray(res.list)) {
        this.roomList = res.list;
      }
    });

    this.getList();
  },

  watch: {
    selectedDate() {
      this.getList();
    },

    // 监听编辑弹窗是否关闭，刷新数据
    polishVisible(newValue) {
      if (newValue === false) {
        this.getList();
      }
    },
  },

  methods: {
    getList() {
      const { selectedDate, form } = this;
      const { date } = form;

      let startTime, endTime;

      if (Array.isArray(date) && date.length === 2) {
        startTime = date[0].format("YYYY-MM-DD");
        endTime = date[1].format("YYYY-MM-DD");
      }

      if (selectedDate === "1") {
        startTime = moment().startOf("week").format("YYYY-MM-DD");
        endTime = moment().endOf("week").format("YYYY-MM-DD");
      } else if (selectedDate === "2") {
        startTime = moment().endOf("week").add(1, "days").format("YYYY-MM-DD");
        endTime = moment().endOf("week").add(7, "days").format("YYYY-MM-DD");
      } else if (selectedDate === "3") {
        startTime = moment().format("YYYY-MM-DD");
        endTime = startTime;
      } else if (selectedDate === "4") {
        startTime = moment().add(1, "days").format("YYYY-MM-DD");
        endTime = startTime;
      } else if (selectedDate === "5") {
        startTime = moment().add(2, "days").format("YYYY-MM-DD");
        endTime = startTime;
      }

      this.loading = true;
      fetchMeetingList({
        pageNum: 1,
        pageSize: 100,
        startTime,
        endTime,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 监听用户选择时间范围
    handleDateChange() {
      // 把今明后日选择器状态删了
      this.selectedDate = null;
    },

    adopt(text) {
      const content = Array.isArray(text.serviceList)
        ? text.serviceList.map((item) => item.name).join("，")
        : "";
      const that = this;
      this.$confirm({
        title: "确认要通过这次预约申请吗？",
        content: content ? `会议服务：${content}` : undefined,
        onOk() {
          adoptMeeting({
            id: text.id,
            state: "approved",
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    reject(text) {
      const that = this;
      this.$confirm({
        title: "确认要驳回这次预约申请吗？",
        onOk() {
          adoptMeeting({
            id: text.id,
            state: "rejected",
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    cancel(text) {
      const that = this;
      this.$confirm({
        title: "确认要取消这次预约申请吗？",
        onOk() {
          cancelMeeting({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    polish(text) {
      this.text = text;
      this.polishVisible = true;
    },
    cancelPolish() {
      this.polishVisible = false;
    },

    reset() {
      if (this.selectedDate === "1") {
        this.getList();
      } else {
        this.selectedDate = "1";
        this.form = {};
      }
    },

    getTime(time) {
      if (typeof time === "string") {
        const arr = time.split(" ");
        if (arr.length > 1) {
          return arr[1];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
};
</script>


<style lang="less" scoped>
.scroll-panel {
  overflow-x: auto;
}
.list {
  display: flex;
  align-items: stretch;
  .item {
    flex: 1;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>