<template>
  <a-modal title="修改" :visible="visible" width="640px" @cancel="cancel" :footer="null">
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" :colon="false">
      <a-form-item label="申请服务">
        <a-checkbox-group v-model="selectedServiceList" :options="serviceList.map((item) => item.name)" />
        <div style="color: #999">请至少提前一天联系相关人员对接会议需求</div>
      </a-form-item>

      <a-divider />

      <div class="right">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" @click="save" :loading="saveLoading">保存</a-button>
        </a-space>
      </div>
    </a-form>
  </a-modal>
</template>

<script>
import { editMeeting } from "@/api/meeting";
import { mapGetters } from "vuex";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      selectedServiceList: [],
      saveLoading: false,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    serviceList() {
      return this.findDataDict("meetBookService");
    },
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        let arr = [];
        if (Array.isArray(this.text.serviceList)) {
          arr = this.text.serviceList.map((item) => item.name);
        }
        this.selectedServiceList = arr;
      }
    },
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },

    save() {
      this.saveLoading = true;
      editMeeting({
        ...this.text,

        serviceList: Array.isArray(this.selectedServiceList)
          ? this.selectedServiceList.map((item) => ({
            name: item,
          }))
          : undefined,
      })
        .then(() => {
          this.cancel();
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
