<template>
  <div class="room">
    <div slot="title" class="left room-header">
      <a-icon theme="twoTone" type="calendar" style="font-size: 16px; margin-right: 8px" />
      <span style="color: #1890ff; font-weight: bold; font-size: 13px">{{
        room.name
      }}</span>
    </div>

    <div class="content">
      <div v-if="list.length > 0">
        <div v-for="(item, index) in list" :key="index" class="meeting-item">
          <div class="title" :title="item.title">
            {{ item.title }}
          </div>

          <div class="info" title="会议状态">
            <a-icon type="info-circle" class="icon" />
            <DataDictFinder dictType="meetingBookState" :dictValue="item.state" iconType="text" />
          </div>
          <div class="info" title="申请部门">
            <a-icon type="team" class="icon" />
            {{ item.applicantDept }}
          </div>
          <div class="info" title="申请人员">
            <a-icon type="user" class="icon" />
            {{ item.applicantName }}
          </div>
          <div class="info" title="会议日期">
            <a-icon type="calendar" class="icon" />
            {{ getDate(item.startTime) }}
          </div>
          <div class="info" title="会议时间">
            <a-icon type="clock-circle" class="icon" />
            {{ getTime(item.startTime) }} ~
            {{ getTime(item.endTime) }}
          </div>

          <div class="info" title="会议服务">
            <a-icon type="smile" class="icon" />
            {{
              Array.isArray(item.serviceList) && item.serviceList.length > 0
              ? item.serviceList
                .map((service) => getService(service.code))
                .join("，")
              : "无"
            }}
          </div>

          <div class="footer right">
            <template v-if="hasPermission(item)">
              <a-space>
                <template v-if="item.state === 'applying'">
                  <PermissionButton path="approve">
                    <a href="#" @click.prevent="$emit('adopt', item)">通过</a>
                  </PermissionButton>
                  <PermissionButton path="approve">
                    <a href="#" @click.prevent="$emit('reject', item)">驳回</a>
                  </PermissionButton>
                </template>
                <template v-if="item.state === 'rejected'">
                  <PermissionButton path="edit">
                    <router-link :to="`${$route.path}/edit?id=${item.id}`">重新申请</router-link>
                  </PermissionButton>
                </template>

                <!-- 审核通过后可以润色一下（修改会议服务） -->
                <template v-if="item.state === 'approved'">
                  <PermissionButton path="edit">
                    <a href="#" @click.prevent="$emit('polish', item)">编辑</a>
                  </PermissionButton>
                </template>
                <template v-if="item.state !== 'cancelled'">
                  <PermissionButton path="delete">
                    <a href="#" style="color: #f00" @click.prevent="$emit('cancel', item)">取消会议</a>
                  </PermissionButton>
                </template>
              </a-space>
            </template>
          </div>
        </div>
      </div>

      <div v-else style="margin-top: 40px">
        <a-empty />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: {
    room: {
      type: Object,
      default: () => ({}),
    },

    // 所有的会议记录
    meetingList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    serviceList() {
      return this.findDataDict("meetBookService");
    },

    ficilityTypeList() {
      return this.findDataDict("facility-type");
    },

    // 过滤出自己这个会议室的数据
    list() {
      return this.meetingList.filter(
        (element) => element.meetRoom && element.meetRoom.id === this.room.id
      );
    },

    ...mapGetters("auth", ["isMeetingAdmin"]),
    ...mapState("auth", ["user"]),
  },

  methods: {
    getService(code) {
      const obj = this.serviceList.find((item) => item.value === code);
      if (obj) {
        return obj.name;
      } else {
        return "";
      }
    },

    getType(code) {
      const obj = this.ficilityTypeList.find((item) => item.value === code);
      if (obj) {
        return obj.icon;
      } else {
        return "";
      }
    },

    getDate(time) {
      if (typeof time === "string") {
        const arr = time.split(" ");
        if (arr.length > 0) {
          return arr[0];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    getTime(time) {
      if (typeof time === "string") {
        const arr = time.split(" ");
        if (arr.length > 1) {
          return arr[1];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    // 申请记录底部操作栏是否可用
    hasPermission(item) {
      if (this.isMeetingAdmin) {
        return true;
      } else {
        return item.applicantId === this.user.uuid;
      }
    },
  },
};
</script>


<style lang="less" scoped>
.room {
  border: 1px solid #e0e0e0;
  height: 100%;

  .room-header {
    padding: 8px;
    border-bottom: 1px solid #e0e0e0;
  }

  .content {
    padding: 8px;
  }
}

.meeting-item {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;
  margin-bottom: 8px;

  &:last-child {
    border-bottom-width: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 1;

    overflow: hidden;
  }

  .info {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    color: #999;
  }

  .info .icon {
    margin-right: 4px;
  }

  .footer {
    padding-top: 8px;
  }
}
</style>